import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { secureBusinessKey, apiEndpoints } from "./.info";

export const fetchSlides = createAsyncThunk(
  "categories/fetchSlides",
  async () => {
    try {
      const response = await axios.get(apiEndpoints.categories.url, {
        headers: {
          apikey: apiEndpoints.categories.apikey,
          "secure-business-key": secureBusinessKey,
          apiconnection: "appmobile",
          lang: "en",
        },
      });
      // console.log("res", response.data.data.data);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to fetch slides"
      );
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    slides: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSlides.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSlides.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Extracts the nested array from response.data.data.data
        state.slides = Array.isArray(action.payload.data.data)
          ? action.payload.data.data
          : [];
      })

      .addCase(fetchSlides.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const categoriesReducer = categoriesSlice.reducer;
