//
//
//
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secureBusinessKey, apiEndpoints } from "./.info";

//

export const getCodeData = createAsyncThunk("code/getCodeData", async () => {
  const phone = sessionStorage.getItem("phone");

  const options = {
    url: apiEndpoints.sendCode.url,
    method: "POST",
    headers: {
      apikey: apiEndpoints.sendCode.apikey,
      "secure-business-key": secureBusinessKey,
    },
    data: {
      customer_phone: phone,
    },
  };

  try {
    const { data } = await axios.request(options);
    // console.log(data?.data);
    return data;
  } catch (error) {
    // console.log(error);
    return error;
  }
});

const initialValues = {
  code: null,
  isLoading: false,
  isError: null,
};

const sendCodeSlice = createSlice({
  name: "code",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(getCodeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.code = action.payload;
        // sessionStorage.setItem("code", action.payload.data.code);
      })
      .addCase(getCodeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCodeData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
        // console.log(action.error);
      });
  },
  reducers: {},
});

export const sendCodeReducer = sendCodeSlice.reducer;
