//
//
//
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsData } from "../redux/productsSlice";
import { toast, Bounce } from "react-toastify";
import Loader from "../components/loader";
import ProductsCard from "../components/productsCard";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlinePhotoCamera } from "react-icons/md";
import Slider from "../components/Slider";
// import ProductsCart from "../components/productsCart";

export default function Shop1() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const ProductsData = useSelector((state) => state?.products);
  const productsIsLoading = ProductsData?.isLoading;
  const products = ProductsData?.products?.data?.data;
  // console.log("products", products);
  //
  useEffect(() => {
    dispatch(getProductsData());
  }, [dispatch]);
  //
  const [cartItems, setCartItems] = useState({
    product: [],
    product_id: [],
    product_price: [],
    product_qty: [],
    professionals: [],
    professionals_id: [],
  });
  //
  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("cartItems")) || {
      product: [],
      product_id: [],
      product_price: [],
      product_qty: [],
      professionals: [],
      professionals_id: [],
    };
    if (storedItems) {
      setCartItems(storedItems);
    }
  }, []);
  const handleAddToCart = (item) => {
    // Make a copy of the current products in the cart
    let updatedProducts = [...cartItems.product];
    //
    let updatedProductsId = [...cartItems.product_id];
    let updatedProductsPrice = [...cartItems.product_price];
    // Make a copy of the current quantities in the cart
    let updatedQuantities = [...cartItems.product_qty];

    let updatedProfessionals = [...cartItems.professionals];
    let updatedProfessionalsId = [...cartItems.professionals_id];

    //
    // Check if the item is already in the cart
    const existingProductIndex = updatedProducts.findIndex(
      (cartProduct) => cartProduct.id === item.id
    );
    //
    // If the item is already in the cart, increment its quantity by 1
    if (existingProductIndex >= 0) {
      updatedQuantities[existingProductIndex] += 1;
    }
    // If the item is not in the cart, add it to the cart with a quantity of 1
    else {
      updatedProducts.push(item);
      updatedProductsId.push(item.id);
      updatedProductsPrice.push(item.customer_price);
      updatedQuantities.push(1);
      updatedProfessionals.push("no");
      updatedProfessionalsId.push(null);
    }
    //
    // Create a new object with the updated products and quantities
    const updatedCartItems = {
      product: updatedProducts,
      product_id: updatedProductsId,
      product_price: updatedProductsPrice,
      product_qty: updatedQuantities,
      professionals: updatedProfessionals,
      professionals_id: updatedProfessionalsId,
    };
    //
    // Update the state of the cart with the new object
    setCartItems(updatedCartItems);
    // Save the updated cart to local storage
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    // console.log("wtth", updatedCartItems);
    //
    // Show a notification to the user that the item has been added to the cart
    notify();
  };
  //
  const notify = () =>
    toast("Added successfully", {
      style: {
        background: "#A4907C",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
      transition: Bounce,
    });
  return (
    <section className="pt-8 h-full">
      <div className="container-box flex justify-between items-center">
        <h3 className="!z-10 text-[16px] sm:text-[20px] md:text-[34px] lg:text-[40px]  xl:text-[50px]  2xl:text-[58px]  3xl:text-[68px] 4xl:text-[80px] 5xl:text-[90px] 6xl:text-[100px] 7xl:text-[120px] font-bold">
          Select Product
        </h3>
        <div className="flex gap-2 md:gap-4">
          <div
            onClick={() => {
              sessionStorage.removeItem("cartItems");
              navigate(-1);
            }}
            className="cursor-pointer flex justify-center items-center py-1 px-3 md:py-2 md:px-4 lg:py-3 lg:px-6 border-main border-2 rounded-2xl "
          >
            <img
              src="./images/ArrowBendUpLeft.jpg"
              alt="ArrowBendUpLeft"
              className="w-[24px] h-[24px] md:w-[26px] md:h-[26px] lg:w-[32px] lg:h-[32px]  xl:h-[38px]  2xl:h-[45px]  3xl:h-[50px] 4xl:h-[58px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[38px]  2xl:w-[45px]  3xl:w-[50px] 4xl:w-[58px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px]"
            />
          </div>
          <Link to="/camera">
            <div className="flex items-center gap-3 py-2 px-3 md:py-2 md:px-4 lg:py-3 lg:px-6 border-main border-2 rounded-2xl bg-[#e7ded5]">
              <p className="text-[12px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] font-bold">
                Selfies{" "}
              </p>
              <MdOutlinePhotoCamera className="w-[16px] h-[16px] md:w-[20px] md:h-[20px] lg:w-[24px] lg:h-[24px]  xl:h-[28px]  2xl:h-[38px]  3xl:h-[45px] 4xl:h-[56px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[28px]  2xl:w-[38px]  3xl:w-[45px] 4xl:w-[56px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px] text-main " />
            </div>
          </Link>
        </div>
      </div>
      {productsIsLoading ? (
        <Loader />
      ) : (
        <div className="w-full flex">
          <div id="slider-container" className="w-1/4 mx-auto">
            <Slider />
          </div>
          <div
            id="shop1-container"
            className="container-box w-3/4 grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3   py-4 mx-auto md:py-6"
          >
            {products &&
              products.map((item, i) => (
                <ProductsCard
                  key={i}
                  item={item}
                  handleAddToCart={handleAddToCart}
                />
              ))}
          </div>
        </div>
      )}
    </section>
  );
}
