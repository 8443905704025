//
//
//
import { useEffect } from "react";
import Logo from "../icons/logo";
// import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function Welcome() {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <section className="relative" id="welcome">
      {/* <img
        src="./images/welcome.png"
        className="absolute top-0 left-0 w-full h-full z-0 object-cover"
      /> */}
      <img
        src="/images/top-Line.png"
        className="absolute top-0 left-0 w-full h-full z-10"
      />
      <img
        src="/images/bttom-Lines.png"
        className="absolute bottom-0 left-0 w-full h-fullz-10"
      />
      <div className="container-box relative flex flex-col justify-center w-full min-h-screen mx-auto z-20 text-white">
        <div className="mx-auto w-fit">
          <Logo
            fill={"black"}
            className="h-[110px] w-[210px] md:h-[220px] md:w-[340px] lg:w-[400px] lg:h-[280px]
             xl:w-[460px] xl:h-[340px] 2xl:w-[520px] 2xl:h-[400px] 3xl:w-[580px] 3xl:h-[460px] 4xl:w-[640px] 4xl:h-[520px] 
             5xl:w-[700px] 5xl:h-[600px] 6xl:w-[760px] 6xl:h-[680px] 7xl:w-[1000px] 7xl:h-[900px]"
          />
        </div>
        <h1
          className=" text-nowrap text-xl sm:text-4xl md:text-7xl xl:text-9xl  2xl:text-[138px] 
        3xl:text-[150px] 4xl:text-[165px] 5xl:text-[170px] 6xl:text-[190px] 7xl:text-[210px] text-center mx-auto"
        >
          {/* {t("welcome")} */}
          Welcome to
          <span className="text-black ms-6 font-black">
            {/* {t("layers")} */}
            Layers
          </span>
        </h1>
        {/* <h1 className=" text-[30px] sm:text-[40px] md:text-[70px] lg:text-[100px] text-center mx-auto">
    
          Welcome to
          <span className="text-black ms-6 font-black">
           
            Layers
          </span>
        </h1> */}
        <p
          className="w-full  md:px-16 lg:px-28 3xl:px-44 text-center mx-auto  font-light 
        text-[15px] sm:text-[25px]  md:text-[34px] lg:text-[48px] xl:text-[56px] 2xl:text-[65px] 
        3xl:text-[75px] 4xl:text-[90px] 5xl:text-[100px] 6xl:text-[110px] 7xl:text-[130px]
       leading-[30px] md:leading-[40px] lg:leading-[56.35px] xl:leading-[70px] 4xl:leading-[110px]
        5xl:leading-[130px] 7xl:leading-[150px]"
        >
          {/* {t("unwind")} */}
          Unwind and embrace your unique beauty with our personalized treatments
          and exceptional service.
        </p>
        <Link to={"/home"}>
          <button className=" w-4/5 md:w-3/5 mx-auto font-semibold my-14 bg-white text-main block py-2 rounded-[10px] md:rounded-[14px] text-[16px] sm:text-[20px] md:text-[28px] lg:text-[36px] xl:text-[44px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px] capitalize">
            {/* {t("start")} */}
            get started
          </button>
        </Link>
      </div>
    </section>
  );
}
