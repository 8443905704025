//
//
//
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secureBusinessKey, apiEndpoints } from "./.info";

//
export const varifyCodeData = createAsyncThunk(
  "varify/varifyCodeData",
  async () => {
    const phone = sessionStorage.getItem("phone");
    const code = sessionStorage.getItem("code");
    //
    const options = {
      url: apiEndpoints.codeVerification.url,
      method: "POST",
      headers: {
        apikey: apiEndpoints.codeVerification.apikey,
        "secure-business-key": secureBusinessKey,
      },
      data: {
        customer_phone: phone,
        code: code,
      },
    };

    try {
      const { data } = await axios.request(options);
      // console.log(data?.data);
      return data;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
);

const initialValues = {
  customerId: null,
  isLoading: false,
  isError: null,
};

const codeVerifySlice = createSlice({
  name: "varify",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(varifyCodeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerId = action.payload;
        localStorage.setItem("customer_id", action.payload?.data?.customer_id);
        // console.log(action.payload);
      })
      .addCase(varifyCodeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(varifyCodeData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
        // console.log(action.error);
      });
  },
  reducers: {},
});

export const codeVerifyReducer = codeVerifySlice.reducer;
