import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { fetchSlides } from "../redux/categoriesSlice";

export default function Slider() {
  const dispatch = useDispatch();
  const { slides = [], status = "idle" } = useSelector(
    (state) => state?.getCategories
  );
  // console.log("Slides:", slides);
  // console.log(slides?.length, "length of slider");
  const [depth, setDepth] = useState(100);

  useEffect(() => {
    dispatch(fetchSlides());

    const updateDepth = () => setDepth(100);
    window.addEventListener("resize", updateDepth);
    return () => window.removeEventListener("resize", updateDepth);
  }, [dispatch]);

  const handleSlideChange = (swiper) => {
    const activeSlideId = slides[swiper.activeIndex]?.id;
    console.log("Selected slide ID:", activeSlideId);
  };
  if (status === "loading") {
    return <p className="text-[2dvh]">Loading slides...</p>;
  }
  if (status === "failed") {
    return <p className="text-[2dvh]">Failed to load slides.</p>;
  }

  return (
    <div
      //   className="
      // w-[200px] h-[200px]  3xl:w-[500px] 3xl:h-[500px] 4xl:w-[600px] 4xl:h-[600px] 5xl:w-[700px]
      // 5xl:h-[700px] 6xl:w-[800px] 6xl:h-[800px]
      //  7xl:w-full 7xl:h-full
      //   mx-auto px-2 sm:px-3 md:px-4 lg:px-5 xl:px-6"
      className="
    w-full h-fit
 relative
      mx-auto px-2 sm:px-3 md:px-4 lg:px-5 xl:px-6"
    >
      <div className="fixed top-0 ">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          direction={"vertical"}
          loop={false}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: depth,
            modifier: slides.length + 1,
            slideShadows: false,
          }}
          onSlideChange={handleSlideChange}
          className=""
        >
          {slides?.map((slide) => (
            <SwiperSlide key={slide.id} id={slide.id}>
              <div
                className=" h-fit w-fit
              p-2 sm:p-3 md:p-4 lg:p-8 xl:p-12 2xl:p-16 3xl:p-24 4xl:p-32 5xl:p-40 6xl:p-48 7xl:p-52
               rounded-3xl border-[1.5px] border-black/20 7xl:rounded-[7rem] 7xl:border-[1rem] 6xl:rounded-[6rem] 6xl:border-[0.8rem] 5xl:rounded-[5rem] 5xl:border-[0.5rem]
              4xl:rounded-[4rem] 4xl:border-[0.4rem] 3xl:rounded-[3rem] 3xl:border-[0.3rem]
               shadow-custom bg-white flex flex-col justify-center gap-8"
                // className=" h-[200px] w-[200px] 3xl:w-[500px] 3xl:h-[500px] 4xl:w-[600px] 4xl:h-[600px] 5xl:w-[700px] 5xl:h-[700px] 6xl:w-[800px] 6xl:h-[800px] 7xl:w-[900px] 7xl:h-[900px]
                //  rounded-3xl border-[1.5px] border-black/20 7xl:rounded-[7rem] 7xl:border-[1rem] 6xl:rounded-[6rem] 6xl:border-[0.8rem] 5xl:rounded-[5rem] 5xl:border-[0.5rem]
                // 4xl:rounded-[4rem] 4xl:border-[0.4rem] 3xl:rounded-[3rem] 3xl:border-[0.3rem]
                //  shadow-custom bg-white flex flex-col justify-center gap-8"
              >
                <img
                  // src={"./images/slide-1.png"} // Optional fallback
                  src={slide.image || "./images/slide-1.png"} // Optional fallback
                  alt="Slide Image"
                  className="mx-auto h-[70px] w-[70px] sm:h-[90px] sm:w-[90px] md:h-[120px] md:w-[120px] lg:h-[160px] lg:w-[160px] xl:h-[200px] xl:w-[200px] 2xl:h-[280px] 2xl:w-[280px] 3xl:w-[340px] 3xl:h-[340px] 4xl:w-[400px] 4xl:h-[400px] 5xl:w-[460px] 5xl:h-[460px] 6xl:w-[520px] 6xl:h-[520px] 7xl:w-[600px] 7xl:h-[600px]"
                />
                <p className="text-center font-bold text-[12px] sm:text-[14px] md:text-[18px] lg:text-[22px] xl:text-[24px]  2xl:text-[28px]  3xl:text-[32px] 4xl:text-[40px] 5xl:text-[45px] 6xl:text-[52px] 7xl:text-[58px]">
                  {slide.title}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

// -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// // import React, { useState } from "react";
// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/effect-coverflow";
// import { fetchSlides } from "../redux/categoriesSlice";
// // import { useEffect } from "react";

// const Slider = () => {
//   const dispatch = useDispatch();
//   const { slides = [], status = "idle" } = useSelector(
//     (state) => state?.getCategories
//   );

//   useEffect(() => {
//     dispatch(fetchSlides());
//   }, [dispatch]);

//   return (
//     <div className="flex flex-col items-center space-y-4 h-screen overflow-hidden">
//       <Swiper
//         grabCursor={true}
//         direction="vertical"
//         slidesPerView={slides.length - 1}
//         centeredSlides={true}
//         spaceBetween={0}
//         effect={"coverflow"}
//         coverflowEffect={{
//           rotate: 0,
//           stretch: 0,
//           depth: 130,
//           modifier: 1,
//           slideShadows: false,
//         }}
//         loop={false}
//         className="h-[400px] w-[200px] sm:w-[220px] sm:h-[420px] md:w-[240px] md:h-[440px] lg:w-[260px] lg:h-[460px] xl:w-[280px] xl:h-[480px] 2xl:w-[300px] 2xl:h-[500px] 3xl:w-[320px] 3xl:h-[520px] 4xl:w-[340px] 4xl:h-[540px] 5xl:w-[360px] 5xl:h-[560px] 6xl:w-[380px] 6xl:h-[580px] 7xl:w-[400px] 7xl:h-[600px] 8xl:w-[420px] 8xl:h-[620px] 9xl:w-[440px] 9xl:h-[640px]"
//       >
//         {slides.map((item) => (
//           <SwiperSlide key={item.id}>
//             <div className="flex flex-col items-center justify-center h-[100px] w-[200px] sm:w-[220px] sm:h-[120px] md:w-[240px] md:h-[140px] lg:w-[260px] lg:h-[160px] xl:w-[280px] xl:h-[180px] 2xl:w-[300px] 2xl:h-[200px] 3xl:w-[320px] 3xl:h-[220px] 4xl:w-[340px] 4xl:h-[240px] 5xl:w-[360px] 5xl:h-[260px] 6xl:w-[380px] 6xl:h-[280px] 7xl:w-[400px] 7xl:h-[300px] 8xl:w-[420px] 8xl:h-[320px] 9xl:w-[440px] 9xl:h-[340px] bg-white rounded-lg border-4">
//               <img
//                 src={item.image}
//                 alt={item.title}
//                 className="h-12 w-12 object-contain"
//               />
//               <p className="text-center text-gray-700">{item.title}</p>
//             </div>
//           </SwiperSlide>
//         ))}
//       </Swiper>
//     </div>
//   );
// };

// export default Slider;
