import React, { useState } from "react";
import PhoneIcon from "../icons/phoneIcon";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { FaRegKeyboard } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getCodeData } from "../redux/sendCodeSlice";
import Spinner from "../components/spinner";

export default function PhoneVerification() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const codeData = useSelector((state) => state?.sendCode?.code);
  const codeMessage = codeData?.message;

  const validationForm = yup.object({
    country: yup.string().required(`${t("countryRequired")}`),
    customer_phone: yup
      .string()
      .required(`${t("numberRequired")}`)
      .test("valid-phone", "Number is not valid", function (value) {
        const { country } = this.parent;
        if (value && value.length === 7) {
          value = value + "00";
        }
        const phoneNumber = parsePhoneNumberFromString(
          value,
          country === "KSA" ? "SA" : "EG"
        );
        return phoneNumber ? phoneNumber.isValid() : false;
      }),
  });

  const formik = useFormik({
    initialValues: {
      country: "KSA",
      customer_phone: "",
    },
    validationSchema: validationForm,
    onSubmit: async (values, { resetForm }) => {
      console.log(values.customer_phone);

      if (formik.isValid) {
        const countryCode = values.country === "KSA" ? "+966" : "+20";
        const phone = countryCode + values.customer_phone;
        sessionStorage.setItem("phone", phone);

        setIsLoading(true); // Show loader

        try {
          // Dispatch getCodeData and wait for it to complete
          const result = await dispatch(getCodeData()).unwrap(); // Use .unwrap() for async action handling

          setIsLoading(false); // Hide loader after code is generated

          if (result) {
            navigate("/codeVerification"); // Navigate only if codeData is generated successfully
          } else {
            console.log("Failed to generate code");
          }
        } catch (error) {
          console.error("Error generating code:", error);
          setIsLoading(false); // Hide loader in case of error
        }

        resetForm();
      } else {
        console.log("Invalid form");
      }
    },
  });

  const [phoneInput, setPhoneInput] = useState("");

  const handleKeyPress = (value) => {
    if (value === "delete") {
      setPhoneInput(phoneInput.slice(0, -1));
    } else {
      setPhoneInput(phoneInput + value);
    }
    formik.setFieldValue("customer_phone", phoneInput + value);
  };

  const handleKeyDown = (e) => {
    if (e.key >= "0" && e.key <= "9") {
      setPhoneInput((prev) => prev + e.key);
      formik.setFieldValue("customer_phone", phoneInput + e.key);
    } else if (e.key === "Backspace") {
      setPhoneInput((prev) => prev.slice(0, -1));
      formik.setFieldValue("customer_phone", phoneInput.slice(0, -1));
    }
  };

  return (
    <section className="">
      {isLoading && <Spinner />}{" "}
      {/* Show Loader component if isLoading is true */}
      <article
        className="relative h-[100vh] container-box bg-verification flex flex-col justify-center"
        id="verification"
      >
        <div className="mx-auto w-2/3 border border-white backdrop-blur-md bg-white/30 rounded-2xl overflow-hidden">
          <h1
            className="text-center text-[#66381a] pb-10 pt-4 text-[20px] sm:text-[44px] md:text-[54px] lg:text-[64px]
            xl:text-[80px] 2xl:text-[94px] 3xl:text-[110px] 4xl:text-[120px] 5xl:text-[130px] 6xl:text-[140px] 7xl:text-[160px] font-semibold"
          >
            Phone Verification
          </h1>
          <form className="mx-auto w-4/5" onSubmit={formik.handleSubmit}>
            <div className="flex items-center border bg-white border-[#E5E5E5] gap-4 rounded-[10px] md:rounded-[14px] lg:rounded-[20px] ps-6">
              <PhoneIcon className="w-14 h-14 3xl:w-28 3xl:h-28" />
              <div>
                <select
                  name="country"
                  className="block w-[60px] md:w-[70px] lg:w-[80px] xl:w-[94px] 2xl:w-[106px] 3xl:w-[120px] 4xl:w-[132px] 5xl:w-[145px] 6xl:w-[160px] 7xl:w-[230px] text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px] 2xl:text-[38px] 3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] focus:outline-none bg-transparent"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                >
                  <option value="KSA">KSA</option>
                  <option value="EGY">EGY</option>
                </select>
              </div>
              <div className="w-full">
                <input
                  type="tel"
                  name="customer_phone"
                  aria-autocomplete="off"
                  value={phoneInput}
                  placeholder="Enter your phone number"
                  className="w-full text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px] 2xl:text-[38px] 3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] border-none focus:outline-none rounded-md py-6 bg-transparent"
                  readOnly
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            {formik.errors.customer_phone && formik.touched.customer_phone ? (
              <span className="text-red-700 text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px] 2xl:text-[38px] 3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
                * {formik.errors.customer_phone}{" "}
              </span>
            ) : null}
            {codeMessage === "Enter customer phone" &&
            formik.errors.customer_phone &&
            formik.touched.customer_phone ? (
              <span className="text-red-700 text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px] 2xl:text-[38px] 3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
                ({codeMessage})
              </span>
            ) : null}
            <button
              type="submit"
              className="w-full bg-[#e7ded5] text-main border border-main py-4 my-10 font-bold rounded-[10px] md:rounded-[14px] lg:rounded-[20px] text-[20px] md:text-[24px] lg:text-[30px] xl:text-[34px] 2xl:text-[40px] 3xl:text-[48px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[85px]"
            >
              Verify
            </button>
          </form>
          <div className="text-center">
            <p className="text-black font-bold text-[16px] sm:text-[20px] md:text-[26px] lg:text-[36px] xl:text-[44px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px]">
              Don’t Have Account{" "}
            </p>
            <p className="text-black font-bold text-[10px] sm:text-[12px] md:text-[16px] lg:text-[20px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
              Scan QR Code and install App
            </p>
          </div>
          <img
            src="/images/qr.png"
            alt="qr"
            className="w-[120px] md:w-[160px] lg:w-[220px] xl:w-[260px] 3xl:w-[300px] 4xl:w-[340px] 5xl:w-[380px] 6xl:w-[420px] 7xl:w-[500px] mx-auto"
          />
          <div
            onClick={() => setIsShowKeyboard(true)}
            className="cursor-pointer mx-auto w-fit"
          >
            <FaRegKeyboard className="text-black w-[30px] h-[30px] md:w-[40px] md:h-[40px] lg:w-[50px] lg:h-[50px] xl:w-[60px] xl:h-[60px] 2xl:w-[70px] 2xl:h-[70px] 3xl:w-[80px] 3xl:h-[80px] 4xl:w-[90px] 4xl:h-[90px] 5xl:w-[100px] 5xl:h-[100px] 6xl:w-[110px] 6xl:h-[110px] 7xl:w-[120px] 7xl:h-[120px]" />
          </div>
          {/* keyboard */}
          <div
            className={`${
              isShowKeyboard ? "" : "hidden"
            } absolute bottom-0 left-1/2 -translate-x-1/2 bg-white w-full pb-4`}
          >
            <img
              onClick={() => setIsShowKeyboard(false)}
              src="/images/x mark.png"
              alt="qr"
              className="
            w-[60px] cursor-pointer ms-8
            "
            />
            <div
              className={`${
                isShowKeyboard ? "" : "hidden"
              } container-box grid grid-cols-3 gap-4 w-full mx-auto`}
            >
              {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map((num) => (
                <button
                  key={num}
                  onClick={() => handleKeyPress(num)}
                  className="p-4 bg-gray-300 text-[30px] rounded-lg"
                >
                  {num}
                </button>
              ))}
              <button
                onClick={() => handleKeyPress("delete")}
                className="col-span-2 p-4 bg-main text-white text-[30px] rounded-lg"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
}
