// import * as yup from "yup";
// import { useFormik } from "formik";
// import { useEffect, useState } from "react";
// import { FaEye, FaEyeSlash, FaUserCircle } from "react-icons/fa";
// import { useDispatch, useSelector } from "react-redux";
// import { getLoginData } from "../redux/authSlice";
// import { useNavigate } from "react-router-dom";
// import { toast, Bounce } from "react-toastify";
// import { FaRegKeyboard } from "react-icons/fa";
// import Keyboard from "react-simple-keyboard";
// import "react-simple-keyboard/build/css/index.css";

// export default function LogIn() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [showPassword, setShowPassword] = useState(false);
//   const [inputName, setInputName] = useState(""); // الحقل النشط
//   const [input, setInput] = useState({ user_name: "", password: "" }); // القيم المدخلة
//   const [isShowKeyboard, setIsShowKeyboard] = useState(false);
//   const togglePasswordVisibility = (type) => {
//     if (type === "show") setShowPassword(!showPassword);
//   };

//   const logInData = useSelector((state) => state?.auth?.user);
//   const logInStatus = logInData?.status;

//   useEffect(() => {
//     dispatch(getLoginData());
//   }, [dispatch]);

//   useEffect(() => {
//     if (logInStatus === true) {
//       notify();
//       setTimeout(() => navigate("/"), 2000);
//     } else if (logInStatus === false) {
//       navigate("/login");
//     }
//   }, [logInStatus]);

//   const validationForm = yup.object({
//     user_name: yup.string().required("User Name is required"),
//     password: yup.string().required("Password is required"),
//   });

//   const formik = useFormik({
//     initialValues: { user_name: "", password: "" },
//     onSubmit: (values) => dispatch(getLoginData(values)),
//     validationSchema: validationForm,
//   });

//   const notify = () =>
//     toast("Logged in successfully", {
//       style: {
//         background: "#A4907C",
//         color: "#fff",
//         fontWeight: "bold",
//         fontSize: "1.2rem",
//         padding: "1rem",
//       },
//       position: "top-center",
//       autoClose: 2000,
//       hideProgressBar: false,
//       pauseOnHover: true,
//       draggable: true,
//       theme: "colored",
//       transition: Bounce,
//     });

//   const handleKeyboardInput = (inputValue) => {
//     const updatedInput = { ...input, [inputName]: inputValue };
//     setInput(updatedInput);
//     formik.setFieldValue(inputName, inputValue);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setInput({ ...input, [name]: value });
//     formik.handleChange(e);
//   };

//   return (
//     <>
//       <section className="container-box h-[100vh] flex flex-col justify-center items-center gap-8">
//         <div className="p-6 rounded-xl w-[80vw] md:w-[60vw] lg:w-[40vw] mx-auto border">
//           <h2 className="text-2xl md:text-3xl lg:text-4xl mb-20 flex items-center gap-2 text-main">
//             <FaUserCircle />
//             <span className="capitalize">sign in</span>
//           </h2>
//           <form onSubmit={formik.handleSubmit}>
//             <div className="mb-2 border border-[#E5E5E5] gap-4 rounded-[15px] md:rounded-[20px] lg:rounded-[30px]">
//               <input
//                 type="text"
//                 placeholder="User Name"
//                 className="w-full text-[16px] md:text-[18px] lg:text-[20px] text-gray-500 border-none focus:outline-none rounded-md py-2 px-4 bg-transparent"
//                 name="user_name"
//                 onFocus={() => setInputName("user_name")}
//                 onChange={handleInputChange}
//                 onBlur={formik.handleBlur}
//                 value={input.user_name}
//               />
//             </div>
//             {formik.errors.user_name && formik.touched.user_name && (
//               <span className="text-red-500">* {formik.errors.user_name}</span>
//             )}
//             <div className="mb-2 mt-4 relative border border-[#E5E5E5] gap-4 rounded-[15px] md:rounded-[20px] lg:rounded-[30px]">
//               <input
//                 type={showPassword ? "text" : "password"}
//                 placeholder="Password"
//                 className="w-full text-[16px] md:text-[18px] lg:text-[20px] text-gray-500 border-none focus:outline-none rounded-md py-2 px-4 bg-transparent"
//                 name="password"
//                 onFocus={() => setInputName("password")}
//                 onChange={handleInputChange}
//                 onBlur={formik.handleBlur}
//                 value={input.password}
//               />
//               <button
//                 type="button"
//                 onClick={() => togglePasswordVisibility("show")}
//                 className="absolute right-3 top-1/2 transform -translate-y-1/2"
//               >
//                 {showPassword ? (
//                   <FaEyeSlash className="h-6 w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 text-gray-600" />
//                 ) : (
//                   <FaEye className="h-6 w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 text-gray-600" />
//                 )}
//               </button>
//             </div>
//             {formik.errors.password && formik.touched.password && (
//               <span className="text-red-500">* {formik.errors.password}</span>
//             )}
//             {logInData?.message === "incorrect user name or password" && (
//               <span className="text-red-500">* {logInData?.message}</span>
//             )}

//             <button
//               type="submit"
//               className="mainButton w-full py-2 mb-4 mt-12 font-semibold text-white rounded-[30px] text-[18px] md:text-[22px] lg:text-[26px]"
//             >
//               Sign In
//             </button>
//           </form>
//           <div
//             onClick={() => setIsShowKeyboard(!isShowKeyboard)}
//             className="cursor-pointer mx-auto w-fit"
//           >
//             <FaRegKeyboard className="w-[30px] h-[30px] md:w-[40px] md:h-[40px] lg:w-[50px] lg:h-[50px]" />
//           </div>
//         </div>
//         <div
//           className={`${isShowKeyboard ? "" : "hidden"} w-full`}
//         >

//         <Keyboard
//           onChange={(input) => handleKeyboardInput(input)}
//           layoutName="default"
//         />
//         </div>
//       </section>
//     </>
//   );
// }

import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getLoginData } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { toast, Bounce } from "react-toastify";
import { FaRegKeyboard } from "react-icons/fa";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export default function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputName, setInputName] = useState("");
  const [layout, setLayout] = useState("default");
  const [isCapsLock, setIsCapsLock] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);
  const [input, setInput] = useState({ user_name: "", password: "" });

  const togglePasswordVisibility = (type) => {
    if (type === "show") setShowPassword(!showPassword);
  };

  const logInData = useSelector((state) => state?.auth?.user);
  const logInStatus = logInData?.status;

  useEffect(() => {
    dispatch(getLoginData());
  }, [dispatch]);

  useEffect(() => {
    if (logInStatus === true) {
      notify();
      setTimeout(() => navigate("/"), 2000);
    } else if (logInStatus === false) {
      navigate("/login");
    }
  }, [logInStatus]);

  const validationForm = yup.object({
    user_name: yup.string().required("User Name is required"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: { user_name: "", password: "" },
    onSubmit: (values) => dispatch(getLoginData(values)),
    validationSchema: validationForm,
  });

  const notify = () =>
    toast("Logged in successfully", {
      style: {
        background: "#A4907C",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      transition: Bounce,
    });

  const handleKeyboardInput = (inputValue) => {
    const updatedInput = { ...input, [inputName]: inputValue };
    setInput(updatedInput);
    formik.setFieldValue(inputName, inputValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
    formik.handleChange(e);
  };

  const handleShift = () => {
    setLayout((prevLayout) => (prevLayout === "default" ? "shift" : "default"));
  };

  const handleCapsLock = () => {
    setIsCapsLock(!isCapsLock);
    setLayout(isCapsLock ? "default" : "shift");
  };

  return (
    <>
      <section className="container-box h-[100vh] flex flex-col justify-center items-center gap-6">
        <div className="p-6 rounded-xl border w-[80vw] md:w-[60vw] lg:w-[40vw] mx-auto">
          <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-[44px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px] mb-16 flex items-center gap-2 text-main">
            <FaUserCircle />
            <span className="capitalize">sign in</span>
          </h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-2 border border-[#E5E5E5] gap-4 rounded-[15px] md:rounded-[20px] lg:rounded-[30px]">
              <input
                type="text"
                placeholder="User Name"
                className="w-full text-[16px] md:text-[18px] lg:text-[20px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] text-gray-500 border-none focus:outline-none rounded-md py-2 px-4 bg-transparent"
                name="user_name"
                onFocus={() => setInputName("user_name")}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                value={input.user_name}
              />
            </div>
            {formik.errors.user_name && formik.touched.user_name && (
              <span className="text-red-500">* {formik.errors.user_name}</span>
            )}
            <div className="mb-2 mt-4 relative border border-[#E5E5E5] gap-4 rounded-[15px] md:rounded-[20px] lg:rounded-[30px]">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="w-full text-[16px] md:text-[18px] lg:text-[20px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] text-gray-500 border-none focus:outline-none rounded-md py-2 px-4 bg-transparent"
                name="password"
                onFocus={() => setInputName("password")}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                value={input.password}
              />
              <button
                type="button"
                onClick={() => togglePasswordVisibility("show")}
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
              >
                {showPassword ? (
                  <FaEyeSlash
                    className="h-6 w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 xl:w-10 xl:h-10 2xl:w-12 2xl:h-12 3xl:w-14
                       3xl:h-14 4xl:w-16 4xl:h-16 5xl:w-20 5xl:h-20 text-gray-600"
                  />
                ) : (
                  <FaEye
                    className="h-6 w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 xl:w-10 xl:h-10 2xl:w-12 2xl:h-12 3xl:w-14
                       3xl:h-14 4xl:w-16 4xl:h-16 5xl:w-20 5xl:h-20 text-gray-600"
                  />
                )}
              </button>
            </div>
            {formik.errors.password && formik.touched.password && (
              <span className="text-red-500">* {formik.errors.password}</span>
            )}
            {logInData?.message === "incorrect user name or password" && (
              <span className="text-red-500">* {logInData?.message}</span>
            )}

            <button
              type="submit"
              className="mainButton w-full py-2 mt-12 mb-4 font-semibold text-white rounded-[30px] text-[18px] md:text-[22px] lg:text-[26px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]"
            >
              Sign In
            </button>
          </form>
          <div
            onClick={() => setIsShowKeyboard(!isShowKeyboard)}
            className="cursor-pointer mx-auto w-fit"
          >
            <FaRegKeyboard className="w-[30px] h-[30px] md:w-[40px] md:h-[40px] lg:w-[50px] lg:h-[50px] xl:w-[60px] xl:h-[60px] 2xl:w-[70px] 2xl:h-[70px] 3xl:w-[80px] 3xl:h-[80px] 4xl:w-[90px] 4xl:h-[90px] 5xl:w-[100px] 5xl:h-[100px] 6xl:w-[110px] 6xl:h-[110px] 7xl:w-[120px] 7xl:h-[120px]" />
          </div>
        </div>
        <div className={`${isShowKeyboard ? "" : "hidden"} w-full`}>
          <Keyboard
            layoutName={layout}
            onChange={handleKeyboardInput}
            onKeyPress={(button) => {
              if (button === "{shift}" || button === "{lock}") handleShift();
              if (button === "{lock}") handleCapsLock();
            }}
            className="text-base
              sm:text-lg
              md:text-xl
              lg:text-2xl
              xl:text-9xl"
            style={{ fontSize: "10rem" }}
          />
        </div>
      </section>
    </>
  );
}
// className="
// text-base
// sm:text-lg
// md:text-xl
// lg:text-2xl
// xl:text-3xl
// 2xl:text-4xl
// 3xl:text-[400px]
// 4xl:text-[400px]
// 5xl:text-[400px]
// 6xl:text-[400px]
// 7xl:!text-[400px]
// "
