import React from "react";
import { FiTrash2 } from "react-icons/fi";
import ProductsAddRemove from "./productAddRemove";

export default function ProductsCart({ cartItems, setCartItems }) {
  // Update the quantity in the cart
  const handleIncreaseQty = (index) => {
    const updatedItems = { ...cartItems };
    updatedItems.product_qty[index] += 1;

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };

  const handleDecreaseQty = (index) => {
    const updatedItems = { ...cartItems };

    if (updatedItems.product_qty[index] > 1) {
      updatedItems.product_qty[index] -= 1;
    }

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };

  const handleRemove = (index) => {
    const updatedItems = { ...cartItems };

    updatedItems.product.splice(index, 1);
    updatedItems.product_id.splice(index, 1);
    updatedItems.product_price.splice(index, 1);
    updatedItems.product_qty.splice(index, 1);
    updatedItems.professionals.splice(index, 1);
    updatedItems.professionals_id.splice(index, 1);

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };

  return (
    // <section className="flex flex-col justify-between" id="cart-container">
    //   {sessionStorage.getItem("cartItems") && cartItems?.product?.length > 0 ? (
    //     <>
    //       <div
    //         className="container-box flex flex-col gap-3 py-6 overflow-y-auto"
    //         id="cart-content"
    //       >
    <>
      {cartItems?.product?.map((data, i) => {
        return (
          <div key={i} className="product-cart">
            <div className="p-3 rounded-xl">
              <div className="flex items-center gap-3">
                <div
                  className="w-[70px]  h-[130px]  sm:w-[90px] md:w-[110px] lg:w-[130px]  xl:w-[150px]  2xl:w-[180px]  3xl:w-[210px] 4xl:w-[230px] 5xl:w-[250px] 6xl:w-[270px] 7xl:w-[300px] 
                 sm:h-[150px] md:h-[160px] lg:h-[180px]  xl:h-[210px]  2xl:h-[240px]  3xl:h-[270px] 4xl:h-[300px] 5xl:h-[330px] 6xl:h-[360px] 7xl:h-[400px] "
                >
                  <img
                    src={data?.image}
                    alt={data?.name}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
                <div className="w-full">
                  <div className="">
                    <h3 className="font-rosarivo text-[18px] sm:text-[24px] md:text-[30px] lg:text-[38px] xl:text-[45px]  2xl:text-[56px]  3xl:text-[66px] 4xl:text-[75px] 5xl:text-[80px] 6xl:text-[87px] 7xl:text-[100px] font-bold">
                      {data?.descriptions[0]?.name || "No Name"}
                    </h3>
                    <p className="text-main text-[10px] sm:text-[14px] md:text-[18px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] font-bold">
                      {data?.currancy || "SAR"} {data?.price}
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    {/* <div className="flex items-center gap-3 md:gap-4 lg:gap-6">
                      <div
                        onClick={() => handleDecreaseQty(i)}
                        className="flex justify-center items-center bg-secondary cursor-pointer border
                         border-main rounded-full h-[20px] w-[20px] md:h-[28px] md:w-[28px] lg:h-[32px]
                          lg:w-[32px] xl:h-[38px]  2xl:h-[45px]  3xl:h-[50px] 4xl:h-[58px] 5xl:h-[66px] 
                          6xl:h-[75px] 7xl:h-[80px] xl:w-[38px]  2xl:w-[45px]  3xl:w-[50px] 4xl:w-[58px]
                           5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px] "
                      >
                        <FaMinus
                          className="w-3 h-3 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10 2xl:w-12 2xl:h-12 3xl:w-14
                       3xl:h-14 4xl:w-16 4xl:h-16 5xl:w-20 5xl:h-20 text-[#3F1A01]"
                        />
                      </div>
                      <p
                        className="text-[16px] sm:text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] 
                       2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 
                       7xl:text-[80px] font-semibold"
                      >
                        {cartItems.product_qty[i]}
                      </p>
                      <div
                        onClick={() => handleIncreaseQty(i)}
                        className="flex justify-center items-center bg-secondary cursor-pointer border border-main rounded-full h-[20px] w-[20px] md:h-[28px] md:w-[28px] lg:h-[32px] lg:w-[32px] xl:h-[38px]  2xl:h-[45px]  3xl:h-[50px] 4xl:h-[58px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[38px]  2xl:w-[45px]  3xl:w-[50px] 4xl:w-[58px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px]"
                      >
                        <FaPlus
                          className="w-3 h-3 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10 2xl:w-12 2xl:h-12 3xl:w-14
                       3xl:h-14 4xl:w-16 4xl:h-16 5xl:w-20 5xl:h-20 text-[#3F1A01]"
                        />
                      </div>
                    </div> */}
                    <ProductsAddRemove
                      i={data?.id}
                      handleIncreaseQty={handleIncreaseQty}
                      handleDecreaseQty={handleDecreaseQty}
                      cartItems={cartItems}
                    />
                    <div
                      onClick={() => handleRemove(i)}
                      className="flex justify-center items-center bg-red-100 cursor-pointer rounded-[12px]
                       h-[24px] w-[24px] md:h-[28px] md:w-[28px] lg:h-[32px] lg:w-[32px] xl:h-[38px]  2xl:h-[45px]
                         3xl:h-[50px] 4xl:h-[58px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[38px]  2xl:w-[45px] 
                          3xl:w-[50px] 4xl:w-[58px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px]"
                    >
                      <FiTrash2
                        className="w-3 h-3 md:w-4 md:h-4 lg:w-6 lg:h-6 xl:w-8 xl:h-8 2xl:w-10 2xl:h-10 3xl:w-12
                       3xl:h-12 4xl:w-16 4xl:h-16 5xl:w-20 5xl:h-20 text-red-400"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
    //       </div>
    //     </>
    //   ) : (
    //     <div className="container-box h-full flex items-center justify-center py-6">
    //       <h3 className="text-center text-[18px] sm:text-[22px] md:text-[26px] lg:text-[30px] font-bold">
    //         Your Cart Is Empty
    //       </h3>
    //     </div>
    //   )}
    // </section>
  );
}
