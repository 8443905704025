export default function Header() {
  return (
    <header className="container-box bg-[#EFE8E2] shadow-md shadow-slate-300 z-10">
      <nav className="flex items-center justify-between">
        <div className="">
          <p className="text-[20px] sm:text-[26px] md:text-[40px] lg:text-[54px] 3xl:text-[80px] 4xl:text-[95px] 5xl:text-[110px] 6xl:text-[130px] 7xl:text-[150px]   font-semibold ">
            Enjoy Exclusive
          </p>
          <p className="text-[20px] sm:text-[26px] md:text-[40px] lg:text-[54px] 3xl:text-[80px] 4xl:text-[95px] 5xl:text-[110px] 6xl:text-[130px] 7xl:text-[150px] font-extrabold">
            Rewards
          </p>
        </div>
        <div
          className="w-[140px] h-[120px] sm:w-[200px] sm:h-[155px] md:w-[220px] md:h-[180px] lg:w-[360px] lg:h-[295px]  3xl:w-[460] 3xl:h-[400px] 
         4xl:w-[560px] 4xl:h-[500px] 5xl:w-[660px] 5xl:h-[600px] 6xl:w-[760px] 6xl:h-[700px]  7xl:w-[860px] 7xl:h-[800px] md:me-10"
        >
          <img src="./images/Images 1.jpg" alt="" className="w-full h-full" />
        </div>
      </nav>
    </header>
  );
}
