//
//
//
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secureBusinessKey, apiEndpoints } from "./.info";

//
export const getProductsData = createAsyncThunk(
  "products/getProductsData",
  async (pageNumber) => {
    const options = {
      url: apiEndpoints.getProducts.url(pageNumber),

      method: "GET",
      headers: {
        apikey: apiEndpoints.getProducts.apikey,
        lang: "ar",
        apiconnection: "appmobile",
        "secure-business-key": secureBusinessKey,
      },
    };

    try {
      const { data } = await axios.request(options);
      // console.log(data);
      return data;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
);

const initialValues = {
  products: [],
  token: null,
  isLoading: false,
  isError: null,
};

const productsSlice = createSlice({
  name: "products",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder.addCase(getProductsData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
      // console.log(action.payload);
    });
    builder.addCase(getProductsData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductsData.rejected, (state, action) => {
      state.isError = action.error;
      state.isLoading = false;
      // console.log(action.error);
    });
  },
  reducers: {},
});

export const productsReducer = productsSlice.reducer;
