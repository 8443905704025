import React from "react";
export default function ServicesCard({ item, handleAddToCart }) {
  return (
    <>
      <div
        className="relative cursor-pointer"
        onClick={() => {
          handleAddToCart(item);
        }}
      >
        <div
          className="bg-slate-200 w-[200px] h-[200px] 
                                    3xl:w-[260px] 3xl:h-[260px] 
                                    4xl:w-[320px] 4xl:h-[320px] 
                                    5xl:w-[380px] 5xl:h-[380px] 
                                    6xl:w-[440px] 6xl:h-[440px] 
                                    7xl:w-[500px] 7xl:h-[500px] 
      
                                  rounded-full mx-auto"
        >
          <img
            className="object-cover w-full h-full object-center rounded-full"
            src={item?.image}
            alt={item?.name}
          />
        </div>
        <div className="my-4 mx-auto w-fit">
          <h3 className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]  font-semibold text-center">
            {item?.descriptions[0]?.name}
          </h3>
          <p className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]  text-gray-700 font-bold text-center">
            SAR {item?.customer_price}
          </p>
        </div>
      </div>
    </>
  );
}
