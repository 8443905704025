//
//
//
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsData } from "../redux/productsSlice";
import { toast, Bounce } from "react-toastify";
import Loader from "../components/loader";
import ProductsCard from "../components/productsCard";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlinePhotoCamera } from "react-icons/md";
import ProductsCart from "../components/productsCart";
import { FaLongArrowAltRight } from "react-icons/fa";
import Slider from "../components/Slider";
export default function Shop2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  //
  const ProductsData = useSelector((state) => state?.products);
  const productsIsLoading = ProductsData?.isLoading;
  const products = ProductsData?.products?.data?.data;

  // console.log("products", products);

  //
  useEffect(() => {
    dispatch(getProductsData());
  }, [dispatch]);
  //
  const [cartItems, setCartItems] = useState({
    product: [],
    product_id: [],
    product_price: [],
    product_qty: [],
    professionals: [],
    professionals_id: [],
  });
  //
  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("cartItems")) || {
      product: [],
      product_id: [],
      product_price: [],
      product_qty: [],
      professionals: [],
      professionals_id: [],
    };
    if (storedItems) {
      setCartItems(storedItems);
    }
  }, []);
  const handleAddToCart = (item) => {
    // Make a copy of the current products in the cart
    let updatedProducts = [...cartItems.product];
    //
    let updatedProductsId = [...cartItems.product_id];
    let updatedProductsPrice = [...cartItems.product_price];
    // Make a copy of the current quantities in the cart
    let updatedQuantities = [...cartItems.product_qty];
    // Make a copy of the current professionals in the cart
    let updatedProfessionals = [...cartItems.professionals];
    let updatedProfessionalsId = [...cartItems.professionals_id];
    console.log("item", item);
    //
    // Check if the item is already in the cart
    const existingProductIndex = updatedProducts.findIndex(
      (cartProduct) => cartProduct.id === item.id
    );

    //
    // If the item is already in the cart, increment its quantity by 1
    if (existingProductIndex >= 0) {
      updatedQuantities[existingProductIndex] += 1;
    }
    // If the item is not in the cart, add it to the cart with a quantity of 1
    else {
      updatedProducts.push(item);
      updatedProductsId.push(item.id);
      updatedProductsPrice.push(item.customer_price);
      updatedQuantities.push(1);
      updatedProfessionals.push("no");
      updatedProfessionalsId.push(null);
    }
    //
    // Create a new object with the updated products and quantities
    const updatedCartItems = {
      product: updatedProducts,
      product_id: updatedProductsId,
      product_price: updatedProductsPrice,
      product_qty: updatedQuantities,
      professionals: updatedProfessionals,
      professionals_id: updatedProfessionalsId,
    };
    //
    // Update the state of the cart with the new object
    setCartItems(updatedCartItems);
    // Save the updated cart to local storage
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    // console.log("wtth", updatedCartItems);
    //
    // Show a notification to the user that the item has been added to the cart
    notify();
  };
  //
  const notify = () =>
    toast("Added successfully", {
      className: "",
      style: {
        background: "#A4907C",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
      transition: Bounce,
    });
  const totalPrice =
    Math.round(
      cartItems?.product?.reduce(
        (total, product, index) =>
          total + product.price * cartItems.product_qty[index],
        0
      ) * 10
    ) / 10;

  const handleIncreaseQty = (index) => {
    const updatedItems = { ...cartItems };
    updatedItems.product_qty[index] += 1;

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };

  const handleDecreaseQty = (index) => {
    const updatedItems = { ...cartItems };

    if (updatedItems.product_qty[index] > 1) {
      updatedItems.product_qty[index] -= 1;
    }

    // Update session storage and state
    sessionStorage.setItem("cartItems", JSON.stringify(updatedItems));
    setCartItems(updatedItems);
  };
  // console.log("cartItems", cartItems);
  return (
    <>
      <section className="pt-8 h-full overflow-hidden flex flex-col justify-between">
        <div className="">
          <div
            className={`${
              isShow ? "" : "hidden"
            } fixed top-0 right-0 h-[100%] w-[100%] bg-white bg-opacity-50 z-[45]`}
          ></div>
          <div
            className={`${
              isShow
                ? "w-[80vw] sm:w-[60vw] md:w-[45vw] xl:w-[40vw]"
                : "w-[0vw]"
            } fixed right-0 top-0 h-[80vh] bg-white border z-50 pt-10 flex flex-col transition-all duration-500`}
          >
            <div className="flex justify-between items-center px-8">
              <h4 className="text-[14px] sm:text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px]">
                Order summary
              </h4>
              <h4
                onClick={() => {
                  setIsShow(false);
                }}
                className="cursor-pointer flex items-center gap-3 text-[12px] sm:text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]"
              >
                <p className="leading-2">Hide</p>
                <FaLongArrowAltRight />
              </h4>
            </div>
            <div
              className="flex-grow px-8 overflow-y-auto"
              id="shop2-cart-container"
            >
              <ProductsCart cartItems={cartItems} setCartItems={setCartItems} />
            </div>
            <div className="bg-gray-50 border-t-2 border-gray-200 py-14 px-8 space-y-8">
              <div className="flex justify-between items-center text-[12px] sm:text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
                <h4 className="font-bold ">Total</h4>
                <h4 className="flex items-center gap-3">SAR {totalPrice}</h4>
              </div>
              <button
                onClick={() => navigate("/phoneVerification")}
                className="block w-full bg-[#e7ded5] text-[#3F1A01] border border-main py-3 font-bold rounded-[8px] md:rounded-[12px] lg:rounded-[16px] text-[16px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]"
              >
                Confirm
              </button>
            </div>
          </div>
          <div className="container-box flex justify-between items-center">
            <h3 className="!z-10  text-[16px] sm:text-[20px] md:text-[34px] lg:text-[40px]  xl:text-[50px]  2xl:text-[58px]  3xl:text-[68px] 4xl:text-[80px] 5xl:text-[90px] 6xl:text-[100px] 7xl:text-[120px] font-bold">
              Select Products
            </h3>
            <div className="flex gap-2 md:gap-4">
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className="flex justify-center items-center py-1 px-3 md:py-2 md:px-4 lg:py-3 lg:px-6 border-main border-2 rounded-2xl "
              >
                <img
                  src="./images/ArrowBendUpLeft.jpg"
                  alt="ArrowBendUpLeft"
                  className="w-[24px] h-[24px] md:w-[26px] md:h-[26px] lg:w-[32px] lg:h-[32px]  xl:h-[38px]  2xl:h-[45px]  3xl:h-[50px] 4xl:h-[58px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[38px]  2xl:w-[45px]  3xl:w-[50px] 4xl:w-[58px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px] cursor-pointer"
                />
              </div>
              <Link to="/camera">
                <div className="flex items-center gap-3 py-2 px-3 md:py-2 md:px-4 lg:py-3 lg:px-6 3xl:px-10 7xl:px-14 border-main border-2 rounded-2xl bg-[#e7ded5]">
                  <p className="text-[12px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] font-bold">
                    Selfies{" "}
                  </p>
                  <MdOutlinePhotoCamera className="w-[16px] h-[16px] md:w-[20px] md:h-[20px] lg:w-[24px] lg:h-[24px]  xl:h-[28px]  2xl:h-[38px]  3xl:h-[45px] 4xl:h-[56px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[28px]  2xl:w-[38px]  3xl:w-[45px] 4xl:w-[56px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px] text-main " />
                </div>
              </Link>
            </div>
          </div>
          {productsIsLoading ? (
            <Loader />
          ) : (
            <div className="w-full flex">
              <div id="slider-container" className="w-1/4 mx-auto">
                <Slider />
              </div>
              <div
                id="shop2-container"
                className="container-box w-3/4 grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3  py-4 mx-auto md:py-6"
              >
                {products &&
                  products.map((item, i) => (
                    <ProductsCard
                      key={item.id}
                      item={item}
                      handleAddToCart={handleAddToCart}
                      i={i}
                      // cartItems={products}
                      handleDecreaseQty={handleDecreaseQty}
                      handleIncreaseQty={handleIncreaseQty}
                      cartItems={cartItems}
                      setCartItems={setCartItems}
                      products={products}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
        <div className="bg-white z-50">
          <div className="border-b-4"></div>
          <div className="py-2 md:py-3 lg:py-4">
            <div className="container-box flex justify-between gap-4">
              <button
                onClick={() => {
                  sessionStorage.removeItem("cartItems");
                  navigate("/");
                }}
                className="w-1/2 text-center text-red-600 font-semibold md:font-bold bg-[#EFE8E2]
                 rounded-[7px] md:rounded-[14px] text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px] xl:text-[27px]  2xl:text-[32px]  3xl:text-[37px] 4xl:text-[45px] 5xl:text-[50px] 6xl:text-[60px] 7xl:text-[75px]"
              >
                Cancel Order
              </button>
              <button
                onClick={() => {
                  setIsShow(true);
                }}
                disabled={cartItems.product.length === 0}
                className="w-1/2 text-center text-[#66381A] font-semibold md:font-bold bg-[#D6C4B0] 
                rounded-[7px] md:rounded-[14px] text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px] xl:text-[27px]  2xl:text-[32px]  3xl:text-[37px] 4xl:text-[45px] 5xl:text-[50px] 6xl:text-[60px] 7xl:text-[75px]"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
