import React from "react";
import { useNavigate } from "react-router-dom";

export default function DetailsOrder() {
  const navigate = useNavigate();
  const cartItems = JSON.parse(sessionStorage.getItem("cartItems"));
  const products = cartItems?.product;
  const products_qty = cartItems?.product_qty;
  console.log("cartItems", products);
  return (
    <>
      <div className="container-box w-full flex flex-col h-[100vh]">
        <h1 className="font-bold text-center py-10 text-xl md:text-3xl lg:text-5xl">
          Order Details
        </h1>
        <div className=" flex flex-col justify-between">
          <div className="" id="table-content">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2 sm:py-3 md:py-4 lg:py-8 xl:py-11 2xl:py-14 3xl:py-20 4xl:py-28 5xl:py-32 6xl:py-36 7xl:py-40 text-base md:text-xl lg:text-2xl xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
                    Image
                  </th>
                  <th className="px-4 py-2 sm:py-3 md:py-4 lg:py-8 xl:py-11 2xl:py-14 3xl:py-20 4xl:py-28 5xl:py-32 6xl:py-36 7xl:py-40 text-base md:text-xl lg:text-2xl xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
                    Name
                  </th>
                  <th className="px-4 py-2 sm:py-3 md:py-4 lg:py-8 xl:py-11 2xl:py-14 3xl:py-20 4xl:py-28 5xl:py-32 6xl:py-36 7xl:py-40 text-base md:text-xl lg:text-2xl xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
                    Quantity
                  </th>
                  <th className="px-4 py-2 sm:py-3 md:py-4 lg:py-8 xl:py-11 2xl:py-14 3xl:py-20 4xl:py-28 5xl:py-32 6xl:py-36 7xl:py-40 text-base md:text-xl lg:text-2xl xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {products?.map((product, index) => (
                  <tr key={product?.id} className="">
                    <td className="border px-4 py-2 sm:py-3 md:py-4 lg:py-8 xl:py-11 2xl:py-14 3xl:py-20 4xl:py-28 5xl:py-32 6xl:py-36 7xl:py-40 w-[80px] md:w-[100px] lg:w-[120px]">
                      <img
                        src={product?.image}
                        alt=""
                        className="w-full h-full "
                      />
                    </td>
                    <td className="border px-4 py-2 text-center text-lg md:text-2xl lg:text-4xl xl:text-[44px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px] font-semibold">
                      {product?.descriptions[0]?.name || product?.keyword}
                    </td>
                    <td className="border px-4 py-2 text-center text-lg md:text-2xl lg:text-4xl xl:text-[44px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px] font-semibold">
                      {products_qty[index]}
                    </td>
                    <td className="border px-4 py-2 text-center text-lg md:text-2xl lg:text-4xl xl:text-[44px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px] font-semibold">
                      {products_qty[index] * product?.price}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            onClick={() => {
              sessionStorage.removeItem("phone");
              sessionStorage.removeItem("cartItems");
              sessionStorage.removeItem("code");
              sessionStorage.removeItem("customer_id");

              navigate("/");
            }}
            className="cursor-pointer"
          >
            <p className="p-[0.8dvh] bg-main text-white text-[10px] lg:text-[38px] xl:text-[45px]  2xl:text-[56px]  3xl:text-[66px] 4xl:text-[75px] 5xl:text-[80px] 6xl:text-[87px] 7xl:text-[100px] rounded-[0.5dvh] text-center ">
              New Operation
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
