const AirPlaneIcon = ({ className, stroke }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_657_50948)">
        <path
          opacity="0.12"
          d="M57.2426 17.2753C58.5803 12.4979 59.2492 10.1092 58.6284 8.47009C58.0872 7.04096 56.9589 5.91266 55.5298 5.3714C53.8906 4.75061 51.5019 5.41944 46.7245 6.75711L19.4554 14.3925C11.9096 16.5053 8.13673 17.5617 7.04347 19.3554C6.09792 20.9068 6.00717 22.8328 6.80266 24.4663C7.72242 26.3549 11.3793 27.7613 18.693 30.5743L25.7933 33.3052C27.0968 33.8066 27.7486 34.0572 28.2969 34.4471C28.7828 34.7926 29.2073 35.2171 29.5528 35.703C29.9426 36.2513 30.1933 36.903 30.6946 38.2066L33.4252 45.3065C36.2381 52.6203 37.6445 56.2772 39.5331 57.197C41.1665 57.9925 43.0926 57.9018 44.644 56.9562C46.4377 55.863 47.4942 52.0901 49.6071 44.5442L57.2426 17.2753Z"
          fill={stroke || "#605F5F"}
        />
        <path
          d="M29.3332 34.6667L33.4252 45.3065C36.2381 52.6203 37.6445 56.2772 39.5331 57.197C41.1665 57.9925 43.0926 57.9018 44.644 56.9562C46.4377 55.863 47.4942 52.0901 49.6071 44.5442L57.2426 17.2753C58.5803 12.4979 59.2492 10.1092 58.6284 8.47009C58.0872 7.04096 56.9589 5.91266 55.5298 5.3714C53.8906 4.75061 51.5019 5.41944 46.7245 6.75711L19.4554 14.3925C11.9096 16.5053 8.13673 17.5617 7.04347 19.3554C6.09792 20.9068 6.00717 22.8328 6.80266 24.4663C7.72242 26.3549 11.3793 27.7613 18.693 30.5743L29.3332 34.6667ZM29.3332 34.6667L37.3332 26.6667"
          stroke={stroke || "#605F5F"}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_657_50948">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AirPlaneIcon;
