import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getEmploeesData } from "../redux/emploeesSlice";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";

export default function Services2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState({
    product: [],
    product_id: [],
    product_price: [],
    product_qty: [],
    professionals: [],
    professionals_id: [],
  });
  // console.log("cartItems", cartItems?.product);
  //
  const [serviceSelectedId, setServiceSelectedId] = useState(null);
  const [proffessionalsModel, setProffessionalsModel] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  // ======= this will get data service employess =======
  const employess = useSelector(
    (state) => state?.getEmploees?.getEmploees?.response
  );
  // console.log("employess", employess);
  useEffect(() => {
    dispatch(getEmploeesData());
  }, [dispatch]);
  //
  useEffect(() => {
    const productsInSession = JSON.parse(sessionStorage.getItem("cartItems"));

    if (productsInSession) {
      setCartItems({
        product: productsInSession.product,
        product_id: productsInSession.product_id,
        product_price: productsInSession.product_price,
        product_qty:
          productsInSession.product_qty ||
          productsInSession.product.map((product) => product.qty || 1),
        professionals: productsInSession.professionals || [],
        professionals_id: productsInSession.professionals_id || [],
      });
    }
  }, []);

  // ====== this will select professional ======
  const handleSelectedProfessional = (id) => {
    const selectedProfessional = employess.find((emp) => emp.user_id === id);
    const updatedProfessionals = [...cartItems.professionals];
    const updatedProfessionalsId = [...cartItems.professionals_id];
    const productIndex = cartItems.product.findIndex(
      (item) => item.id === serviceSelectedId
    );

    updatedProfessionals[productIndex] = selectedProfessional || null;
    updatedProfessionalsId[productIndex] =
      selectedProfessional?.user_id || null;

    sessionStorage.setItem(
      "cartItems",
      JSON.stringify({
        ...cartItems,
        professionals: updatedProfessionals,
        professionals_id: updatedProfessionalsId,
      })
    );
    setCartItems({
      ...cartItems,
      professionals: updatedProfessionals,
      professionals_id: updatedProfessionalsId,
    });
    setProffessionalsModel(false);
  };
  const handleGetEmployees = (serviceId) => {
    const branchId = sessionStorage.getItem("brunch_id");
    setServiceSelectedId(serviceId);
    dispatch(getEmploeesData({ serviceId, branchId }));
    setProffessionalsModel(true);
  };
  //===  =====
  const [existProffessional, setExistProffessional] = useState(false);
  const [existModel, setExistModel] = useState(false);

  useEffect(() => {
    const hasMissingProfessional = cartItems?.professionals?.some(
      (professional) => professional === null
    );
    setExistProffessional(hasMissingProfessional);
    // console.log("hasMissingProfessional", hasMissingProfessional);
  }, [cartItems]);
  const submitOrder = () => {
    if (existProffessional) {
      setExistModel(true);
      setExistProffessional(true);
    } else {
      navigate("/services3");
    }
  };
  return (
    <div className="w-full h-full flex flex-col justify-between">
      <div
        className={`${
          existModel ? "" : "hidden"
        } fixed w-full h-full top-0 left-0 z-[10000] flex justify-center items-center`}
      >
        <div className="relative w-[45vh] h-[30vh] bg-main rounded-xl shadow-2xl">
          <div
            onClick={() => setExistModel(false)}
            className="absolute  flex justify-center items-center cursor-pointer rounded-[18px] h-[56px] w-[56px] md:rounded-[20px] md:h-[64px] md:w-[64px] lg:rounded-[24px] lg:h-[72px] lg:w-[72px]"
          >
            <img
              src="./images/x mark.png"
              alt="x mark"
              className="w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20  xl:w-[90px] xl:h-[90px] 
                         2xl:w-[110px] 2xl:h-[110px] 
                         3xl:w-[120px] 3xl:h-[120px] 
                         4xl:w-[140px] 4xl:h-[140px] 
                         5xl:w-[160px] 5xl:h-[160px] 
                         6xl:w-[190px] 6xl:h-[190px] 
                         7xl:w-[220px] 7xl:h-[220px] "
            />
          </div>
          <div className="w-full h-full flex justify-center items-center">
            <p className="text-white font-semibold text-lg  md:text-2xl lg:text-3xl xl:text-[34px]  2xl:text-[40px]  3xl:text-[48px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[85px] w-full p-4 text-center !leading-normal">
              Please select a professional for each service
            </p>
          </div>
        </div>
      </div>
      <div className={`w-full pt-8`}>
        <div className="container-box flex justify-between items-center">
          <h3 className="text-[16px] sm:text-[20px] md:text-[34px] lg:text-[40px]  xl:text-[50px]  2xl:text-[58px]  3xl:text-[68px] 4xl:text-[80px] 5xl:text-[90px] 6xl:text-[100px] 7xl:text-[120px] font-bold">
            Select Service
          </h3>
          <div className="flex gap-2 md:gap-4">
            <div
              onClick={() => {
                navigate(`/services`);
              }}
              className="cursor-pointer flex justify-center items-center py-1 px-3 md:py-2 md:px-4 lg:py-3 lg:px-6 border-main border-2 rounded-2xl "
            >
              <img
                src="./images/ArrowBendUpLeft.jpg"
                alt="ArrowBendUpLeft"
                className="w-[24px] h-[24px] md:w-[26px] md:h-[26px] lg:w-[32px] lg:h-[32px]  xl:h-[38px]  2xl:h-[45px]  3xl:h-[50px] 4xl:h-[58px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[38px]  2xl:w-[45px]  3xl:w-[50px] 4xl:w-[58px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px]"
              />
            </div>
            <Link to="/camera">
              <div className="flex items-center gap-3 py-2 px-3 md:py-2 md:px-4 lg:py-3 lg:px-6 border-main border-2 rounded-2xl bg-[#e7ded5]">
                <p className="text-[12px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] font-bold">
                  Selfies{" "}
                </p>
                <MdOutlinePhotoCamera className="w-[16px] h-[16px] md:w-[20px] md:h-[20px] lg:w-[24px] lg:h-[24px]  xl:h-[28px]  2xl:h-[38px]  3xl:h-[45px] 4xl:h-[56px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[28px]  2xl:w-[38px]  3xl:w-[45px] 4xl:w-[56px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px] text-main " />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <section
        className={`pt-8 flex flex-col justify-between flex-grow ${
          proffessionalsModel ? "shadow-2xl" : ""
        }`}
        id="services2-container"
      >
        <div
          className={`container-box w-full grid items-center justify-center grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 py-4 mx-auto md:py-6`}
        >
          {cartItems?.product?.map((item, i) => {
            return (
              <div key={i} className="">
                <>
                  <div
                    className="relative cursor-pointer "
                    onClick={() => {
                      setProffessionalsModel(true);
                      handleGetEmployees(item?.id);
                    }}
                  >
                    <div
                      className="bg-slate-200 w-[200px] h-[200px] 
                                                3xl:w-[260px] 3xl:h-[260px] 
                                                4xl:w-[320px] 4xl:h-[320px] 
                                                5xl:w-[380px] 5xl:h-[380px] 
                                                6xl:w-[440px] 6xl:h-[440px] 
                                                7xl:w-[500px] 7xl:h-[500px]  rounded-full mx-auto      bg-transparent"
                    >
                      <img
                        className="object-cover w-full h-full object-center rounded-full "
                        src={item?.image}
                        alt={item?.name}
                      />
                    </div>
                    <div className="my-4 mx-auto w-fit">
                      <h3 className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]  font-semibold text-center">
                        {item?.descriptions[0]?.name}
                      </h3>
                      <p className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px]  text-gray-700 font-bold text-center">
                        SAR {item?.customer_price}
                      </p>
                    </div>
                  </div>
                </>
              </div>
            );
          })}
        </div>
      </section>
      <div className={`${proffessionalsModel ? "hidden" : ""}`}>
        <div className="border-b-4"></div>
        <div className="py-2 md:py-3 lg:py-4">
          <div className="container-box flex justify-between gap-4">
            <button
              onClick={() => {
                sessionStorage.removeItem("cartItems");
                navigate("/");
              }}
              className="w-1/2 text-center text-red-600 font-semibold md:font-bold bg-[#e7ded5]
              rounded-[7px] md:rounded-[14px] text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px] xl:text-[27px]  2xl:text-[32px]  3xl:text-[37px] 4xl:text-[45px] 5xl:text-[50px] 6xl:text-[60px] 7xl:text-[75px]"
            >
              Cancel Order
            </button>
            <button
              onClick={() => submitOrder()}
              disabled={cartItems.product.length === 0}
              className="w-1/2 text-center text-main font-semibold md:font-bold bg-[#e7ded5] 
              rounded-[7px] md:rounded-[14px] text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px] xl:text-[27px]  2xl:text-[32px]  3xl:text-[37px] 4xl:text-[45px] 5xl:text-[50px] 6xl:text-[60px] 7xl:text-[75px]"
            >
              Done
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${proffessionalsModel ? "" : "hidden"} w-full
              rounded-t-2xl overflow-hidden transition-all duration-200`}
        id="modelContent"
      >
        <div
          className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 py-10"
          id="proffessionals-content"
        >
          {employess &&
            employess?.map((employee, i) => (
              <div
                onClick={() => {
                  setSelectedEmployeeId(employee?.user_id);
                }}
                key={i}
                className={` cursor-pointer h-fit`}
              >
                <div
                  className={`${
                    selectedEmployeeId === employee?.user_id
                      ? "border-[8px] border-orange-300"
                      : ""
                  }  w-[200px] h-[200px] 
                3xl:w-[250px] 3xl:h-[250px]
                4xl:w-[320px] 4xl:h-[320px]
                5xl:w-[380px] 5xl:h-[380px]
                6xl:w-[450px] 6xl:h-[450px]
                7xl:w-[520px] 7xl:h-[520px] rounded-full mx-auto relative`}
                >
                  {selectedEmployeeId === employee?.user_id && (
                    <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 rounded-full flex items-center justify-center">
                      <IoMdCheckmark
                        className="text-white  w-[70px] h-[70px] 
                          3xl:w-[90px] 3xl:h-[90px]
                          4xl:w-[110px] 4xl:h-[110px]
                          5xl:w-[130px] 5xl:h-[130px]
                          6xl:w-[140px] 6xl:h-[140px]
                          7xl:w-[170px] 7xl:h-[170px]  "
                      />
                    </div>
                  )}
                  <img
                    src={employee?.profile_url}
                    alt={employee?.user_name}
                    className="w-full h-full  object-cover rounded-full"
                  />
                </div>

                <p className="mt-5 font-semibold text-3xl xl:text-[34px]  2xl:text-[40px]  3xl:text-[48px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[85px] mx-auto w-fit py-6">
                  {employee?.user_name}
                </p>
              </div>
            ))}
        </div>
        <div className="container-box w-full flex justify-center">
          <p
            onClick={() => {
              setProffessionalsModel(false);
            }}
            className="cursor-pointer flex justify-center items-center gap-3 text-red-600 font-semibold lg:text-[32px] xl:text-[40px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px] uppercase py-4 w-full "
          >
            <FaRegTrashAlt /> Cancel
          </p>
          <p
            onClick={() => {
              handleSelectedProfessional(selectedEmployeeId);
            }}
            className="cursor-pointer flex justify-center items-center gap-3 text-lime-600 font-semibold lg:text-[32px] xl:text-[40px]  2xl:text-[52px]  3xl:text-[65px] 4xl:text-[75px] 5xl:text-[90px] 6xl:text-[110px] 7xl:text-[120px] uppercase py-4 w-full "
          >
            <IoMdCheckmarkCircleOutline />
            Confirm
          </p>
        </div>
      </div>
    </div>
  );
}
