//
//
//
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secureBusinessKey, apiEndpoints } from "./.info";
// ${prop.branchId}   1
//${prop.serviceId}  751
export const getEmploeesData = createAsyncThunk(
  "emploees/getEmploeesData",
  async (prop) => {
    const options = {
      url: apiEndpoints.employees.url(prop.serviceId, prop.branchId),
      method: "GET",
      headers: {
        "secure-business-key": secureBusinessKey,
        uuid: apiEndpoints.employees.uuid,
      },
    };

    try {
      const { data } = await axios.request(options);
      console.log(data);
      return data;
    } catch (error) {
      // console.log(error);
      return error;
    }
  }
);

const initialValues = {
  getEmploees: null,
  isLoading: false,
  isError: null,
};

const getEmploeesSlice = createSlice({
  name: "emploees",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(getEmploeesData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getEmploees = action.payload;
        // console.log(action.payload);
      })
      .addCase(getEmploeesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmploeesData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
        // console.log(action.error);
      });
  },
  reducers: {},
});

export const getEmploeesReducer = getEmploeesSlice.reducer;
