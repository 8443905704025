import { useNavigate } from "react-router-dom";
import ProductsAddRemove from "./productAddRemove";

export default function ProductsCard({
  item,
  handleAddToCart,
  cartItems,
  handleDecreaseQty,
  handleIncreaseQty,
}) {
  const navigate = useNavigate();

  return (
    <>
      <div className="relative ">
        <div
          className="w-[275px] h-[350px] 3xl:h-[350px] 3xl:w-[450px] 4xl:h-[450px] 4xl:w-[550px] 7xl:h-[550px] 7xl:w-[650px] mx-auto cursor-pointer"
          onClick={() => {
            handleAddToCart(item);
            navigate("/shop2");
          }}
        >
          <img
            className="w-fit h-full object-center rounded-2xl border-none mx-auto"
            src={item?.image}
            alt={item?.name}
          />
        </div>
        <div className="my-4 mr-auto w-fit mx-auto">
          <h3 className="text-[16px] sm:text-[18px] md:text-[20px] lg:text-[24px] 2xl:text-[3em] 3xl:text-[4em] 4xl:text-[5em] 5xl:text-[6em] 6xl:text-[7em] font-semibold">
            {item?.descriptions[0]?.name || item?.keyword || "No Name"}
          </h3>
          <p className="text-[13px] sm:text-[15px] md:text-[17px] lg:text-[20px] 2xl:text-[2em] 3xl:text-[3em] 4xl:text-[4em] 5xl:text-[5em] 6xl:text-[6em] text-gray-700 font-bold">
            SAR {item?.customer_price}{" "}
            <span className="text-discountText line-through">
              {item?.discount || "SAR 50"}
            </span>
          </p>
          {cartItems && (
            <ProductsAddRemove
              i={item?.id}
              handleIncreaseQty={handleIncreaseQty}
              handleDecreaseQty={handleDecreaseQty}
              cartItems={cartItems}
            />
          )}
        </div>
      </div>
    </>
  );
}
