import React, { useEffect, useState } from "react";
import { toast, Bounce } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getServicesData } from "../redux/servicesSlice";
import Loader from "../components/loader";
import ServicesCard from "../components/servicesCard";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import ServicesCart from "../components/servicesCart";

export default function Services1() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const servicesData = useSelector((state) => state?.services);
  const servicesIsLoading = servicesData?.isLoading;
  const services = servicesData?.services?.data?.data;

  useEffect(() => {
    dispatch(getServicesData());
  }, [dispatch]);

  const [cartItems, setCartItems] = useState({
    product: [],
    product_id: [],
    product_price: [],
    product_qty: [],
    professionals: [],
    professionals_id: [],
  });

  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("cartItems")) || {
      product: [],
      product_id: [],
      product_price: [],
      product_qty: [],
      professionals: [],
      professionals_id: [],
    };
    setCartItems(storedItems);
  }, []);

  const handleAddToCart = (item) => {
    let updatedProducts = [...cartItems.product];
    let updatedProductsId = [...cartItems.product_id];
    let updatedProductsPrice = [...cartItems.product_price];
    let updatedQuantities = [...cartItems.product_qty];
    let updatedProfessionals = [...cartItems.professionals];
    let updatedProfessionalsId = [...cartItems.professionals_id];

    const existingProductIndex = updatedProducts.findIndex(
      (cartProduct) => cartProduct.id === item.id
    );

    if (existingProductIndex >= 0) {
      updatedQuantities[existingProductIndex] += 1;
    } else {
      updatedProducts.push(item);
      updatedProductsId.push(item.id);
      updatedProductsPrice.push(item.customer_price);
      updatedQuantities.push(1);
      updatedProfessionals.push(null);
      updatedProfessionalsId.push(null);
    }

    const updatedCartItems = {
      product: updatedProducts,
      product_id: updatedProductsId,
      product_price: updatedProductsPrice,
      product_qty: updatedQuantities,
      professionals: updatedProfessionals,
      professionals_id: updatedProfessionalsId,
    };

    setCartItems(updatedCartItems);
    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    notify();
  };

  const notify = () =>
    toast("Added successfully", {
      style: {
        background: "#A4907C",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "1rem",
      },
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
      transition: Bounce,
    });

  return (
    <section className="pt-8 h-full flex flex-col justify-between">
      <div className="">
        <div className="container-box flex justify-between items-center px-4 md:px-6">
          <h3 className="text-[16px] sm:text-[20px] md:text-[34px] lg:text-[40px]  xl:text-[50px]  2xl:text-[58px]  3xl:text-[68px] 4xl:text-[80px] 5xl:text-[90px] 6xl:text-[100px] 7xl:text-[120px] font-bold">
            Select Service
          </h3>
          <div className="flex gap-2 md:gap-4">
            <div
              onClick={() => {
                sessionStorage.removeItem("cartItems");
                navigate(-1);
              }}
              className="cursor-pointer flex justify-center items-center py-1 px-3 md:py-2 md:px-4 lg:py-3 lg:px-6 border-main border-2 rounded-2xl "
            >
              <img
                src="./images/ArrowBendUpLeft.jpg"
                alt="ArrowBendUpLeft"
                className="w-[24px] h-[24px] md:w-[26px] md:h-[26px] lg:w-[32px] lg:h-[32px]  xl:h-[38px]  2xl:h-[45px]  3xl:h-[50px] 4xl:h-[58px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[38px]  2xl:w-[45px]  3xl:w-[50px] 4xl:w-[58px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px]"
              />
            </div>
            <Link to="/camera">
              <div className="flex items-center gap-3 py-2 px-3 md:py-2 md:px-4 lg:py-3 lg:px-6 border-main border-2 rounded-2xl bg-[#e7ded5]">
                <p className="text-[12px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] font-bold">
                  Selfies{" "}
                </p>
                <MdOutlinePhotoCamera className="w-[16px] h-[16px] md:w-[20px] md:h-[20px] lg:w-[24px] lg:h-[24px]  xl:h-[28px]  2xl:h-[38px]  3xl:h-[45px] 4xl:h-[56px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[28px]  2xl:w-[38px]  3xl:w-[45px] 4xl:w-[56px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px] text-main " />
              </div>
            </Link>
          </div>
        </div>
        {servicesIsLoading ? (
          <Loader />
        ) : (
          <div
            className="container-box w-full grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-4 mx-auto"
            id="services-container-services1"
          >
            {services?.map((item, i) => (
              <ServicesCard
                key={i}
                item={item}
                handleAddToCart={handleAddToCart}
              />
            ))}
          </div>
        )}
      </div>
      <div className=" bottom-0 left-0 right-0 bg-white">
        <p className="container-box text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[28px]  2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[80px] font-bold bg-main text-white">
          Selected Services
        </p>
        <div className="container-box flex flex-col gap-3 max-h-auto min-height-[10rem] overflow-y-auto py-4">
          {cartItems.product.length > 0 ? (
            <ServicesCart cartItems={cartItems} setCartItems={setCartItems} />
          ) : (
            <div className="flex items-center justify-center py-6">
              <h3 className="text-center text-[18px] sm:text-[22px] md:text-[26px] lg:text-[30px] xl:text-[34px]  2xl:text-[40px]  3xl:text-[48px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[85px] font-bold">
                Your Cart Is Empty
              </h3>
            </div>
          )}
        </div>
        <div className="border-b-4"></div>
        <div className="sticky bottom-0 bg-white py-2 md:py-3 lg:py-4 container-box flex justify-between gap-4">
          <button
            onClick={() => {
              sessionStorage.removeItem("cartItems");
              navigate("/");
            }}
            className="w-1/2 text-center text-red-600 font-semibold md:font-bold bg-[#e7ded5]
            rounded-[7px] md:rounded-[14px] text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px] xl:text-[27px]  2xl:text-[32px]  3xl:text-[37px] 4xl:text-[45px] 5xl:text-[50px] 6xl:text-[60px] 7xl:text-[75px]"
          >
            Cancel Order
          </button>
          <button
            onClick={() => navigate("/services2")}
            disabled={cartItems.product.length === 0}
            className="w-1/2 text-center text-main font-semibold md:font-bold bg-[#e7ded5] 
            rounded-[7px] md:rounded-[14px] text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px] xl:text-[27px]  2xl:text-[32px]  3xl:text-[37px] 4xl:text-[45px] 5xl:text-[50px] 6xl:text-[60px] 7xl:text-[75px]"
          >
            Done
          </button>
        </div>
      </div>
    </section>
  );
}
