import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secureBusinessKey, apiEndpoints } from "./.info";
// import { useState } from "react";

export const getOrderDoneData = createAsyncThunk(
  "order/getOrderDoneData",
  async () => {
    const cartItems = sessionStorage.getItem("cartItems");
    console.log("cartItems", cartItems);

    const servicesIdArray = JSON.parse(cartItems).product_id;
    const servicesQtyArray = JSON.parse(cartItems).product_qty;
    const servicesPriceArray = JSON.parse(cartItems).product_price;
    const employeesIdArray = JSON.parse(cartItems).professionals_id;
    // const updatedEmployeesIdArray = employeesIdArray.map((id) =>
    //   id === null ? 706 : id
    // );
    const todayDate = String(new Date().toISOString().split("T")[0]);

    console.log(todayDate, "today");

    const customerId = localStorage.getItem("customer_id");
    // console.log(customerId);
    // console.log("emploeesIdArray", updatedEmployeesIdArray);
    // console.log("servicesIdArray", servicesIdArray);
    // console.log(items, "items");
    const branchId = sessionStorage.getItem("brunch_id");
    const options = {
      url: apiEndpoints.orderReservation.url,
      method: "POST",
      headers: {
        "Accept-Language": "ar",
        "secure-business-key": secureBusinessKey,
      },
      // data: {
      //   title: "حجز جديد للعميل يوم20-2",
      //   customer_id: 412,
      //   start_date: "2024-08-20",
      //   end_date: "2024-08-20",
      //   start_time: ["10:00"],
      //   end_time: ["10:30"],
      //   service_id: [25],
      //   service_price: [50],
      //   service_qty: [10],
      //   employee_id: [705],
      //   color: "#000",
      //   background_color: "#uuuu",
      //   branch_id: 1,
      //   ecomm_order_id: 11,
      // },
      data: {
        title: "حجز جديد للعميل يوم20-2",
        customer_id: customerId,
        start_date: todayDate,
        end_date: todayDate,
        start_time: ["10:00", "10:00", "10:00"],
        end_time: ["10:30", "10:30", "10:30"],
        service_id: servicesIdArray,
        service_price: servicesPriceArray,
        service_qty: servicesQtyArray,
        employee_id: employeesIdArray,
        color: "#000",
        background_color: "#uuuu",
        branch_id: branchId,
        ecomm_order_id: 11,
      },
    };

    try {
      const { data } = await axios.request(options);
      console.log(data);
      return data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);

const initialValues = {
  orderDone: null,
  isLoading: false,
  isError: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState: initialValues,
  extraReducers: (builder) => {
    builder
      .addCase(getOrderDoneData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orderDone = action.payload;
      })
      .addCase(getOrderDoneData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderDoneData.rejected, (state, action) => {
        state.isError = action.error;
        state.isLoading = false;
      });
  },
});

export const orderReducer = orderSlice.reducer;
