//
//
//
import { Suspense } from "react";
import Cart from "../src/pages/cart";
import AppLayout from "../src/layout";
// import Home from "../src/pages/home/home";
// import Services from "../src/pages/home/service";
// import Products from "./pages/home/products";
import Camera from "../src/pages/home/camera";
import Welcome from "../src/pages/welcome";
import CodeVerification from "../src/pages/codeVerification";
import PhoneVerification from "../src/pages/phoneVerification";
import DoneOrder from "./pages/doneOrder";
import LogIn from "./pages/logIn";
//
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
//
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import DetailsOrder from "./pages/detailsOrder";
import GetStart from "./pages/getStart";
import Services1 from './pages/Services1';
import Services2 from './pages/Services2';
import Services3 from './pages/Services3';
import Shop1 from './pages/Shop1';
import Shop2 from "./pages/Shop2";
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Toaster />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<Welcome />} />
            <Route path="home" element={<GetStart />}/>
            <Route path="services" element={<Services1 />} />
            <Route path="services2" element={<Services2 />} />
            <Route path="services3" element={<Services3 />} />
            <Route path="shop" element={<Shop1 />} />
            <Route path="shop2" element={<Shop2 />} />
            <Route path="camera" element={<Camera />} />
            <Route path="cart" element={<Cart />} />
            <Route path="phoneVerification" element={<PhoneVerification />} />
            <Route path="codeVerification" element={<CodeVerification />} />
            <Route path="doneOrder" element={<DoneOrder />} />
            <Route path="detailsOrder" element={<DetailsOrder />} />
            <Route path="login" element={<LogIn />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
