const PhoneIcon = ({ className }) => {
  return (
    <svg
      width="58"
      height="59"
      viewBox="0 0 58 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.7033 32C8.46055 26.5063 6.31515 20.1128 5.44236 12.9949C5.309 11.9073 5.24231 11.3635 5.32702 10.6658C5.59322 8.47317 7.51613 6.30007 9.66005 5.76899C10.3422 5.60001 11.0324 5.60001 12.4127 5.60001V5.60001C13.6392 5.60001 14.2524 5.60001 14.7918 5.70386C16.6083 6.05355 18.1463 7.25439 18.9262 8.93183C19.1578 9.43002 19.3065 10.0249 19.6039 11.2147L19.7598 11.8381C20.3584 14.2324 20.6576 15.4295 20.7069 16.6016C20.7943 18.6804 20.3171 20.744 19.3259 22.5734C18.7671 23.6048 17.9726 24.549 16.3837 26.4374L11.7033 32ZM11.7033 32C15.1955 37.9165 19.9605 42.7895 25.7791 46.4M25.7791 46.4C31.4533 49.9209 38.1296 52.2412 45.6049 53.1578C46.6928 53.2912 47.2368 53.3579 47.9344 53.2732C50.1271 53.0071 52.3002 51.0841 52.8313 48.9402C53.0003 48.258 53.0003 47.5436 53.0003 46.1148V46.1148C53.0003 44.9369 53.0003 44.3479 52.8964 43.8084C52.5467 41.9919 51.3459 40.4539 49.6684 39.6741C49.1703 39.4425 48.5754 39.2938 47.3856 38.9963L46.5079 38.7769C43.9687 38.1421 42.6991 37.8247 41.4469 37.7892C39.695 37.7395 37.9549 38.0902 36.359 38.8146C35.2183 39.3323 34.1707 40.1167 32.0756 41.6854L25.7791 46.4Z"
        stroke="#2F384C"
        strokeWidth="4.32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneIcon;
