import React from "react";
import Logo from "../icons/logo";
import { Link } from "react-router-dom";

export default function GetStart() {
  return (
    <section className="relative h-full container-box flex flex-col 2xl:justify-start mt-10 2xl:mt-0 w-full  mx-auto z-20 text-white">
      <div className="m-auto w-fit">
        <Logo
          fill={"black"}
          className="h-[110px]  w-[210px] md:h-[220px] md:w-[340px] lg:w-[400px] lg:h-[280px]  xl:w-[460px] xl:h-[340px] 2xl:w-[520px] 2xl:h-[400px] 3xl:w-[580px] 3xl:h-[460px] 4xl:w-[640px] 4xl:h-[520px] 
             5xl:w-[700px] 5xl:h-[600px] 6xl:w-[760px] 6xl:h-[680px] 7xl:w-[1000px] 7xl:h-[900px]"
        />
      </div>
      <div className="mx-auto grid my-auto grid-cols-2  gap-14 w-[80vw]">
        <Link to={"/services"}>
          <div className="relative rounded-[20px] overflow-hidden h-full">
            <img
              src="./images/Rectangle 180.jpg"
              alt=""
              className="w-full h-full"
            />
            <p
              className="absolute bottom-0 left-0 w-full h-5 md:h-10 lg:h-20 xl:h-28 2xl:h-40 3xl:h-48 4xl:h-56 6xl:h-64 7xl:h-72
             bg-[#ddccbb] flex justify-center items-center text-sm sm:text-[16px] 
             md:text-[22px] lg:text-[32px] xl:text-[42px]  2xl:text-[50px]  3xl:text-[68px] 
             4xl:text-[80px] 5xl:text-[90px] 6xl:text-[100px] 7xl:text-[120px] text-center text-black font-bold"
            >
              Services
            </p>
          </div>
        </Link>
        <Link to={"/shop"}>
          <div className="relative rounded-[20px] overflow-hidden h-full">
            <img
              src="./images/Rectangle 181.jpg"
              alt=""
              className="w-full h-full"
            />
            <p
              className="absolute bottom-0 left-0 w-full h-5 md:h-10 lg:h-20 xl:h-28 2xl:h-40 3xl:h-48 4xl:h-56 6xl:h-64 7xl:h-72
             bg-[#ddccbb] flex justify-center items-center text-sm sm:text-[16px] 
             md:text-[22px] lg:text-[32px] xl:text-[42px]  2xl:text-[50px]  3xl:text-[68px] 
             4xl:text-[80px] 5xl:text-[90px] 6xl:text-[100px] 7xl:text-[120px] text-center text-black font-bold"
            >
              Shop
            </p>
          </div>
        </Link>
      </div>
    </section>
  );
}
