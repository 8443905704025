import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";

export default function ProductsAddRemove({
  cartItems,
  handleDecreaseQty,
  handleIncreaseQty,
  i,
}) {
  const itemIndex = cartItems.product_id.findIndex((x) => x === i);

  return (
    <>
      {cartItems.product_qty[itemIndex] && (
        <div className="flex items-center gap-3 md:gap-4 lg:gap-6">
          <div
            onClick={() => handleDecreaseQty(itemIndex)}
            className="flex justify-center items-center bg-secondary cursor-pointer border
                         border-main rounded-full h-[20px] w-[20px] md:h-[28px] md:w-[28px] lg:h-[32px]
                          lg:w-[32px] xl:h-[38px]  2xl:h-[45px]  3xl:h-[50px] 4xl:h-[58px] 5xl:h-[66px] 
                          6xl:h-[75px] 7xl:h-[80px] xl:w-[38px]  2xl:w-[45px]  3xl:w-[50px] 4xl:w-[58px]
                           5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px] "
          >
            <FaMinus
              className="w-3 h-3 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10 2xl:w-12 2xl:h-12 3xl:w-14
                       3xl:h-14 4xl:w-16 4xl:h-16 5xl:w-20 5xl:h-20 text-[#3F1A01]"
            />
          </div>
          <p
            className="text-[16px] sm:text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] 
                       2xl:text-[38px]  3xl:text-[45px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 
                       7xl:text-[80px] font-semibold"
          >
            {cartItems.product_qty[itemIndex]}
          </p>
          <div
            onClick={() => handleIncreaseQty(itemIndex)}
            className="flex justify-center items-center bg-secondary cursor-pointer border border-main rounded-full h-[20px] w-[20px] md:h-[28px] md:w-[28px] lg:h-[32px] lg:w-[32px] xl:h-[38px]  2xl:h-[45px]  3xl:h-[50px] 4xl:h-[58px] 5xl:h-[66px] 6xl:h-[75px] 7xl:h-[80px] xl:w-[38px]  2xl:w-[45px]  3xl:w-[50px] 4xl:w-[58px] 5xl:w-[66px] 6xl:w-[75px] 7xl:w-[80px]"
          >
            <FaPlus
              className="w-3 h-3 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10 2xl:w-12 2xl:h-12 3xl:w-14
                       3xl:h-14 4xl:w-16 4xl:h-16 5xl:w-20 5xl:h-20 text-[#3F1A01]"
            />
          </div>
        </div>
      )}
    </>
  );
}
