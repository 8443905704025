export const secureBusinessKey = "4765066450c0bd66325.48403130";
export const apiEndpoints = {
  addToCart: {
    url: "https://testecommerce.vodoglobal.com/api/v2/abandoned_order",
  },
  authentication: {
    url: "https://testaccounts.vodoerp.com/api/login",
  },
  categories: {
    url: "https://testecommerce.vodoglobal.com/api/v1/ecomm_categories?not_empty=yes",
    apikey: "5f28583f26a1a",
  },
  codeVerification: {
    url: "https://taccounting.vodoerp.com/api/kiosk_code/vertified_code",
    apikey: "efe2db322a53",
  },
  employees: {
    url: (serviceId, branchId) =>
      `https://testhr.vodoglobal.com/api/v1/employees-skill?service_skill_id=${serviceId}&branch_id=${branchId}`,
    uuid: "630ca2f4885f8",
  },
  getFromCart: {
    url: "https://testecommerce.vodoglobal.com/api/v2/abandoned_order",
  },
  orderReservation: {
    url: "https://testcalendar.vodoglobal.com/api/add_new_reservation",
  },
  getProducts: {
    url: (pageNumber = 1) =>
      `https://testecommerce.vodoglobal.com/api/v1/ecomm_products?sku=&page=${pageNumber}&type=purchases&product_kind&min_price&max_price&keyword=&name&view=&per_page=10`,
    apikey: "5f28583f26a1a",
  },
  employeesSearch: {
    url: (serviceId, branchId) =>
      `https://testhr.vodoglobal.com/api/v1/employees-skill?service_skill_id=${serviceId}&branch_id=${branchId}&name=`,
    uuid: "630ca2f4885f8",
  },
  sendCode: {
    url: "https://taccounting.vodoerp.com/api/kiosk_code/send_code",
    apikey: "efe2db322a53",
  },
  getServices: {
    url: (pageNumber = 1) =>
      `https://testecommerce.vodoglobal.com/api/v1/ecomm_products?sku=&page=${pageNumber}&type=Service&product_kind&min_price&max_price&keyword=&name&view=&per_page=6`,
    apikey: "5f28583f26a1a",
  },
  changeQty: {
    url: (propsId) =>
      `https://testecommerce.vodoglobal.com/api/v2/change_item_qty_or_remove/${propsId}`,
  },
};
