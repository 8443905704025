import React from "react";

export default function Loader() {
  return (
    <div className="flex justify-center items-center h-full z-10">
      <div className="loader">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
    </div>
  );
}
