import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function LogoutBtn() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <div
        className={`${
          showModal ? "" : "hidden"
        } absolute w-full h-full top-0 left-0 bg-black bg-opacity-70 z-[10000]`}
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[100]">
          <div className="relative w-[30vw] h-[40vh] rounded-2xl bg-white">
            <div
              onClick={() => {
                setShowModal(false);
              }}
              className="cursor-pointer"
            >
              <img
                src="./../images/x mark.png"
                alt="x mark"
                className="w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20  xl:w-[90px] xl:h-[90px] 
                         2xl:w-[110px] 2xl:h-[110px] 
                         3xl:w-[120px] 3xl:h-[120px] 
                         4xl:w-[140px] 4xl:h-[140px] 
                         5xl:w-[160px] 5xl:h-[160px] 
                         6xl:w-[190px] 6xl:h-[190px] 
                         7xl:w-[220px] 7xl:h-[220px] "
              />
            </div>
            <div className="w-full h-full flex flex-col justify-center items-center">
              <p className=" text-lg md:text-2xl lg:text-3xl xl:text-[34px]  2xl:text-[40px]  3xl:text-[48px] 4xl:text-[56px] 5xl:text-[66px] 6xl:text-[75px] 7xl:text-[85px] font-semibold text-center !leading-normal">
                Are you sure you want to logout?
              </p>
              <div className="w-full h-1/2 flex justify-center items-center gap-3">
                <button
                  onClick={() => {
                    setShowModal(false);
                    sessionStorage.removeItem("phone");
                    sessionStorage.removeItem("cartItems");
                    sessionStorage.removeItem("code");
                    sessionStorage.removeItem("customer_id");
                    navigate("/login");
                  }}
                  className={`w-[5dvw] py-[1dvh]   rounded-[0.5dvw] border-[.3dvh] bg-lime-500 text-white font-semibold border-gray-300 text-xs md:text-xl xl:text-2xl 2xl:text-[28px]  3xl:text-[32px] 4xl:text-[40px] 5xl:text-[45px] 6xl:text-[52px] 7xl:text-[58px]`}
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    setShowModal(false);
                  }}
                  className={`w-[5dvw] py-[1dvh] rounded-[0.5dvw] border-[.3dvh] bg-red-500 text-white font-semibold border-gray-300 text-xs md:text-xl xl:text-2xl 2xl:text-[28px]  3xl:text-[32px] 4xl:text-[40px] 5xl:text-[45px] 6xl:text-[52px] 7xl:text-[58px]`}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          setShowModal(true);
        }}
        className="fixed  bottom-4  right-2  md:bottom-16  md:right-12  lg:bottom-16  lg:right-16 z-[1000]"
      >
        <button
          className="Btn w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14  xl:h-14 2xl:w-16 2xl:h-16 3xl:w-20
                       3xl:h-20 4xl:w-28 4xl:h-w-28 5xl:w-32 5xl:h-32 6xl:w-32 6xl:h-32"
        >
          <div className="sign">
            <svg
              viewBox="0 0 512 512"
              className="w-4 md:w-5 lg:w-6  2xl:w-10
                            3xl:w-12
                            4xl:w-16
                            5xl:w-20 "
            >
              <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
            </svg>
          </div>

          <div
            className="text text-[8px]
          sm:text-[18px] md:text-[24px] lg:text-[30px] xl:text-[34px]  2xl:text-[38px]  3xl:text-[44px] 4xl:text-[48px] 5xl:text-[54px] 6xl:text-[58px] 7xl:text-[64px]
          "
          >
            Logout
          </div>
        </button>
      </div>
    </>
  );
}
